<template>
  <div class="mod-green_application_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ title }}</span>
          <span v-if="showHeaderButton">
            <el-button style="float: right; padding: 3px 3px" type="text" v-if="$hasPermission('miaoins')" @click="editorApplicationInformation()">编辑</el-button>
            <el-button style="float: right; padding: 3px 3px" type="text" v-if="$hasPermission('miaoins')" @click="saveApplicationInformation()">保存</el-button>
          </span>
        </div>
        <div class="basic_information">
          <el-form :inline="true" :disabled="disabled" :model="exclusiveCareForm" ref="exclusiveCareForm" :rules="dataRule" class="demo-form-inline">
            <el-form-item label="意向日期" prop="applyHospitalStartDate">
              <el-date-picker value-format="yyyy-MM-dd" v-model="exclusiveCareForm.applyHospitalStartDate" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="地区" prop="selectedOptions">
              <el-cascader v-model="exclusiveCareForm.selectedOptions" :options="options" :props="{ expandTrigger: 'hover' }" clearable @change="handleChange"></el-cascader>
            </el-form-item>
            <el-form-item label="服务地址" prop="address">
              <el-input v-model="exclusiveCareForm.address" placeholder="服务地址"></el-input>
            </el-form-item>
            <el-form-item label="申请医院">
              <el-input v-model="exclusiveCareForm.applyHospital" placeholder="申请医院"></el-input>
            </el-form-item>
            <el-form-item label="申请科室">
              <el-input v-model="exclusiveCareForm.applyDepartment" placeholder="申请科室"></el-input>
            </el-form-item>
            <el-form-item label="意向医生" v-show="showdoctorName">
              <el-input v-model="exclusiveCareForm.doctorName" placeholder="意向医生"></el-input>
            </el-form-item>
            <el-form-item label="护理天数" prop="applyNursingDays">
              <el-select disabled v-model="exclusiveCareForm.applyNursingDays" placeholder="请选择护理天数">
                <el-option v-for="item in applyNursingDaysOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="当前绿通状态">
              <el-select disabled v-model="exclusiveCareForm.greenStatus" placeholder="请选择">
                <el-option v-for="item in greenStatusOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="需求描述">
              <el-input type="textarea" v-model="exclusiveCareForm.appealDesc" placeholder="需求描述"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
import debounce from "lodash/debounce";
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    return {
      header_button: true,
      showHeaderButton: true,
      disabled: true,
      showAddress: false,
      applyTypeData: [
        { id: 1, name: "住院绿通" },
        { id: 2, name: "门诊绿通" },
        { id: 3, name: "院中陪护" },
      ],
      greenStatusOptions: [
        { id: 0, name: "初审中" },
        { id: 1, name: "初审不通过" },
        { id: 2, name: "需求确认" },
        { id: 3, name: "预约中" },
        { id: 4, name: "预约成功" },
        { id: 6, name: "结案" },
        { id: 7, name: "受理中" },
      ],
      isExpertOptions: [
        { id: 1, name: "是" },
        { id: 0, name: "否" },
      ],
      //   护理天数
      applyNursingDaysOptions: [
        { id: "1", name: "一天(24小时)" },
        { id: "2", name: "二天" },
        { id: "3", name: "三天" },
        { id: "26", name: "26天（包月）" },
      ],
      applyHospitalData: [], // 医院
      applyDepartmentData: [], // 科室
      exclusiveCareForm: {
        id: "",
        greenNo: "",
        type: "",
        applyHospitalStartDate: "",
        applyHospitalEndDate: "",
        applyHospital: "",
        applyDepartment: "",
        doctorName: "",
        greenStatus: "",
        illnessNote: "",
        selectedOptions: [],
      },
      rightCode: "",
      title: "",
      ticketId: "",
      options: [],
      selectedOptions: [],
      showMedicareCard: false,
      showIsExpert: false,
      showappealDesc: false,
      showillnessNote: false,
      showKaiDanDate: false,
      showdoctorName: false,
      showDate: false,
    };
  },
  computed: {
    dataRule() {
      return {
        applyHospitalStartDate: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        applyHospitalEndDate: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        selectedOptions: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
      };
    },
  },
  components: {},
  created() {},
  methods: {
    greenFun(params) {
      console.log("照护", params);
      this.exclusiveCareForm = {
        ...this.exclusiveCareForm,
        ...params.entityDetails,
      };
      this.ticketId = params.id;
      let regionData = [];
      regionData.push(params.entityDetails.province);
      regionData.push(params.entityDetails.city);
      regionData.push(params.entityDetails.district);
      this.exclusiveCareForm.selectedOptions = regionData;
      if (params.status === 2 || params.status === 0) {
        this.showHeaderButton = false;
      } else {
        this.showHeaderButton = true;
      }
      this.rightCode = params.rightCode;
      this.title = params.aliasRightName;
      this.getAreaList();
    },
    getAreaList() {
      this.$http
        .get(`/sys/region/tree`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let dataList = res.data;
          for (let i in dataList) {
            dataList[i].value = dataList[i].id;
            dataList[i].label = dataList[i].name;
          }

          let dataList2 = treeDataTranslate(dataList);
          this.options = dataList2;
        })
        .catch(() => {});
    },
    // 编辑
    editorApplicationInformation() {
      this.header_button = false;
      this.disabled = false;
      this.$emit("fatherMethod");
    },
    // 保存
    saveApplicationInformation() {
      if (!this.selectedOptions) {
        return this.$message.error("地区不能为空!");
      }
      this.$refs["exclusiveCareForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.exclusiveCareForm.ticketId = Number(this.ticketId);
        this.$http
          .post(`/healthbusiness/health-green-channel/update`, this.exclusiveCareForm)
          .then(({ data: res }) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg);
            }
            this.$message({
              message: "保存成功",
              type: "success",
              duration: 500,
              onClose: () => {
                this.visible = false;
                this.header_button = true;
                this.disabled = true;
              },
            });
          })
          .catch(() => {});
      });
    },
    handleChange(e) {
      // console.log(this.selectedOptions);
      this.exclusiveCareForm.province = e[0];
      this.exclusiveCareForm.city = e[1];
      this.exclusiveCareForm.district = e[2];
    },
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
</style>
